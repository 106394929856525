import { LANGUAGES, VIDEO_TYPES } from '@/ui/shared/config/constants';
import { FORM_TYPES } from '@/ui/shared/types/types';
import { z } from 'zod';

export const houseWarmingTags = [
  { sku: 'housewarming-invitation', text: 'Housewarming Invitation' },
  { sku: 'housewarming-party-invite', text: 'Housewarming Party Invite' },
  { sku: 'new-home-celebration', text: 'New Home Celebration' },
  { sku: 'housewarming-wishes', text: 'Housewarming Wishes' },
  { sku: 'housewarming-greetings', text: 'Housewarming Greetings' },
  { sku: 'home-blessing-invitation', text: 'Home Blessing Invitation' },
  { sku: 'housewarming-party-ideas', text: 'Housewarming Party Ideas' },
  { sku: 'warm-wishes-for-new-home', text: 'Warm Wishes For New Home' },
  { sku: 'congratulations-on-new-house', text: 'Congratulations On New House' },
  { sku: 'housewarming-event-invitation', text: 'Housewarming Event Invitation' },
  { sku: 'home-inauguration-invitation', text: 'Home Inauguration Invitation' },
  { sku: 'new-home-blessings', text: 'New Home Blessings' },
  { sku: 'housewarming-party-announcement', text: 'Housewarming Party Announcement' },
  { sku: 'invitation-for-housewarming', text: 'Invitation For Housewarming' },
  { sku: 'housewarming-card-message', text: 'Housewarming Card Message' },
  { sku: 'housewarming-celebration-invite', text: 'Housewarming Celebration Invite' },
  { sku: 'wishes-for-new-home', text: 'Wishes For New Home' },
  { sku: 'happy-housewarming-wishes', text: 'Happy Housewarming Wishes' },
  { sku: 'new-home-party-invite', text: 'New Home Party Invite' },
  { sku: 'best-wishes-for-housewarming', text: 'Best Wishes For Housewarming' },
  { sku: 'home-blessing-event', text: 'Home Blessing Event' },
  { sku: 'new-house-blessings', text: 'New House Blessings' },
  { sku: 'housewarming-event-ideas', text: 'Housewarming Event Ideas' },
  { sku: 'congratulations-on-your-new-home', text: 'Congratulations On Your New Home' },
  { sku: 'personalized-housewarming-invitation', text: 'Personalized Housewarming Invitation' },
  { sku: 'new-home-well-wishes', text: 'New Home Well Wishes' },
  { sku: 'housewarming-quotes', text: 'Housewarming Quotes' },
  { sku: 'housewarming-party-invitation-wording', text: 'Housewarming Party Invitation Wording' },
  { sku: 'best-housewarming-wishes', text: 'Best Housewarming Wishes' },
  { sku: 'funny-housewarming-wishes', text: 'Funny Housewarming Wishes' },
  { sku: 'formal-housewarming-invitation', text: 'Formal Housewarming Invitation' },
  { sku: 'housewarming-celebration-ideas', text: 'Housewarming Celebration Ideas' },
  { sku: 'housewarming-invitation-template', text: 'Housewarming Invitation Template' },
  { sku: 'digital-housewarming-invite', text: 'Digital Housewarming Invite' },
  { sku: 'new-house-party-ideas', text: 'New House Party Ideas' },
  { sku: 'housewarming-invite-design', text: 'Housewarming Invite Design' },
  { sku: 'virtual-housewarming-invitation', text: 'Virtual Housewarming Invitation' },
  { sku: 'housewarming-party-rsvp', text: 'Housewarming Party RSVP' },
  { sku: 'home-inauguration-event', text: 'Home Inauguration Event' },
  { sku: 'happy-new-home-wishes', text: 'Happy New Home Wishes' },
  { sku: 'invitation-card-for-housewarming', text: 'Invitation Card For Housewarming' },
  { sku: 'congratulatory-messages-for-new-home', text: 'Congratulatory Messages For New Home' },
  { sku: 'best-quotes-for-housewarming', text: 'Best Quotes For Housewarming' },
  { sku: 'new-home-house-blessing', text: 'New Home House Blessing' },
  { sku: 'housewarming-party-plan', text: 'Housewarming Party Plan' },
  { sku: 'housewarming-ceremony-invite', text: 'Housewarming Ceremony Invite' },
  { sku: 'housewarming-invitation-text', text: 'Housewarming Invitation Text' },
  { sku: 'housewarming-invitation-online', text: 'Housewarming Invitation Online' },
  { sku: 'warm-wishes-for-housewarming', text: 'Warm Wishes For Housewarming' },
  { sku: 'new-home-greeting-card', text: 'New Home Greeting Card' },
  { sku: 'housewarming-party-online-invite', text: 'Housewarming Party Online Invite' },
  { sku: 'housewarming-celebration-message', text: 'Housewarming Celebration Message' },
  { sku: 'new-home-gift-ideas', text: 'New Home Gift Ideas' },
  { sku: 'housewarming-thank-you-message', text: 'Housewarming Thank You Message' },
  { sku: 'new-home-welcome-message', text: 'New Home Welcome Message' },
  { sku: 'invitation-to-housewarming-ceremony', text: 'Invitation To Housewarming Ceremony' },
  { sku: 'housewarming-messages-for-friends', text: 'Housewarming Messages For Friends' },
  { sku: 'housewarming-party-planning', text: 'Housewarming Party Planning' },
  { sku: 'virtual-housewarming-party', text: 'Virtual Housewarming Party' },
  { sku: 'new-home-card-greetings', text: 'New Home Card Greetings' },
  { sku: 'happy-housewarming-greetings', text: 'Happy Housewarming Greetings' },
  { sku: 'housewarming-celebration-card', text: 'Housewarming Celebration Card' },
  { sku: 'new-home-quotes-for-cards', text: 'New Home Quotes For Cards' },
  { sku: 'blessings-for-new-house', text: 'Blessings For New House' },
  { sku: 'housewarming-quotes-for-friends', text: 'Housewarming Quotes For Friends' },
  { sku: 'housewarming-invitation-message', text: 'Housewarming Invitation Message' },
  { sku: 'new-home-success-wishes', text: 'New Home Success Wishes' },
  { sku: 'inauguration-house-party', text: 'Inauguration House Party' },
  { sku: 'invitation-for-house-blessing', text: 'Invitation For House Blessing' },
  { sku: 'greetings-for-housewarming-ceremony', text: 'Greetings For Housewarming Ceremony' },
  { sku: 'congratulations-on-buying-a-new-house', text: 'Congratulations On Buying A New House' },
  { sku: 'housewarming-party-gift-ideas', text: 'Housewarming Party Gift Ideas' },
  { sku: 'housewarming-announcement', text: 'Housewarming Announcement' },
  { sku: 'housewarming-party-checklist', text: 'Housewarming Party Checklist' },
  { sku: 'housewarming-party-event-planner', text: 'Housewarming Party Event Planner' },
  { sku: 'housewarming-rsvp-ideas', text: 'Housewarming RSVP Ideas' },
  { sku: 'personalized-housewarming-wishes', text: 'Personalized Housewarming Wishes' },
  { sku: 'housewarming-invitations-free', text: 'Housewarming Invitations Free' },
  { sku: 'housewarming-card-wording', text: 'Housewarming Card Wording' },
  { sku: 'housewarming-celebration-rsvp', text: 'Housewarming Celebration RSVP' },
  { sku: 'best-housewarming-card-messages', text: 'Best Housewarming Card Messages' },
  { sku: 'funny-housewarming-party-invites', text: 'Funny Housewarming Party Invites' },
  { sku: 'new-home-blessing-message', text: 'New Home Blessing Message' },
  { sku: 'housewarming-invite-online-free', text: 'Housewarming Invite Online Free' },
  { sku: 'congratulations-on-new-home-purchase', text: 'Congratulations On New Home Purchase' },
  { sku: 'virtual-housewarming-party-ideas', text: 'Virtual Housewarming Party Ideas' },
  { sku: 'housewarming-open-house-invite', text: 'Housewarming Open House Invite' },
  { sku: 'happy-housewarming-ceremony', text: 'Happy Housewarming Ceremony' },
  { sku: 'housewarming-invitation-free-download', text: 'Housewarming Invitation Free Download' },
  { sku: 'creative-housewarming-invitation', text: 'Creative Housewarming Invitation' },
  { sku: 'new-home-blessings-and-wishes', text: 'New Home Blessings And Wishes' },
  { sku: 'e-invite-for-housewarming-party', text: 'E-Invite For Housewarming Party' },
  { sku: 'housewarming-gift-ideas-for-friends', text: 'Housewarming Gift Ideas For Friends' },
  { sku: 'housewarming-invitation-email', text: 'Housewarming Invitation Email' },
  { sku: 'wishes-for-a-new-home-opening', text: 'Wishes For A New Home Opening' },
  { sku: 'simple-housewarming-invite', text: 'Simple Housewarming Invite' }
];

export const houseWarmingInvitationSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  font: z.string(),
  welcomeMsg: z.string(),
  name: z.string(),
  message: z.string(),
  image: z.string().optional(),
  date: z.string(),
  venue: z.string(),
  invitedBy: z.string()
});

export const houseWarmingInviteForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.TEXT, key: 'welcomeMsg', label: 'Enter welcome message' },
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter title' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' },
  { type: FORM_TYPES.FILE, key: 'image', label: 'Select image' },
  { type: FORM_TYPES.TEXT, key: 'date', label: 'Enter date & time' },
  { type: FORM_TYPES.TEXT, key: 'venue', label: 'Enter venue' },
  { type: FORM_TYPES.TEXT, key: 'invitedBy', label: 'Enter invited by' }
];

export const houseWarmingInvitationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      date: 'DATE',
      venue: 'VENUE',
      invitedBy: 'INVITED BY'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'OM GANESHAYA NAMAH',
      name: 'House Warming Ceremony Invitation',
      message: 'We are overjoyed to invite you to the Housewarming Ceremony of our new home. Your presence will make this moment even more special.',
      date: 'Friday, Febraury 28th, 2024',
      venue: 'Swami Vivekanda Road, Indira Nagar, Bangalore.',
      invitedBy: 'Mr. Manish Agarwal & Mrs Anushka Agarwal'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      date: 'तारीख',
      venue: 'जगह',
      invitedBy: 'निमंत्रक'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'ॐ गणेशाय नमः',
      name: 'गृह प्रवेश समारोह निमंत्रण',
      message: 'हमें आपको अपने नए घर के गृहप्रवेश समारोह में आमंत्रित करते हुए बहुत खुशी हो रही है। आपकी मौजूदगी इस पल को और भी खास बना देगी.',
      date: 'शुक्रवार, 28 फ़रवरी 2024',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।',
      invitedBy: 'श्री. मनीष अग्रवाल & श्रीमती. अनुष्का अग्रवाल'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      date: 'పుట్టినరోజు తేదీ',
      venue: 'వేదిక',
      invitedBy: 'ఆహ్వానించువారు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'ఓం గణేశాయ నమః',
      name: 'అగర్వాల్ వారి నూతన గృహప్రవేశ ఆహ్వానం',
      message:
        'మా కొత్త ఇంటి గృహప్రవేశం సందర్భంగా మిమ్మల్ని మా వేడుకకు ఆహ్వానించడానికి ఎంతో ఆనందంగా ఉంది.ఈ ప్రత్యేక సందర్భాన్ని మీరు మరింత ప్రత్యేకంగా చేయాలని ఆశిస్తున్నాము.',
      date: 'శుక్రవారం, ఫిబ్రవరి 28 2024',
      venue: 'వివేకానంద రోడ్డు, ఇందిరానగర్, బెంగుళూరు.',
      invitedBy: 'శ్రీ మనీష్ అగర్వాల్ & శ్రీమతి అనుష్క అగర్వాల్'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      date: 'ದಿನಾಂಕ',
      venue: 'ಸ್ಥಳ',
      invitedBy: 'ಆಹ್ವಾನಿತರು'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'ಓಂ ಗಣೇಶಾಯ ನಮಃ',
      name: 'ಅಗರ್ವಾಲ್ ಅವರ ನೂತನ ಗೃಹಪ್ರವೇಶ ಆಹ್ವಾನ',
      message:
        'ನಮ್ಮ ಹೊಸ ಮನೆಯ ಗೃಹಪ್ರವೇಶ ಸಮಾರಂಭಕ್ಕೆ ನಿಮ್ಮನ್ನು ಆಹ್ವಾನಿಸಲು ನಾವು ತುಂಬಾ ಸಂತೋಷಪಡುತ್ತೇವೆ. ನಿಮ್ಮ ಉಪಸ್ಥಿತಿಯು ಈ ಕ್ಷಣವನ್ನು ಇನ್ನಷ್ಟು ವಿಶೇಷವಾಗಿಸುತ್ತದೆ.',
      date: 'ಶುಕ್ರವಾರ, ಫೆಬ್ರವರಿ 28, 2024',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.',
      invitedBy: 'ಶ್ರೀ ಮನೀಶ್ ಅಗರ್ವಾಲ್ & ಶ್ರೀಮತಿ ಅನುಷ್ಕಾ ಅಗರ್ವಾಲ್'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      date: 'தேதி',
      venue: 'இடம்',
      invitedBy: 'அழைப்பது'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'ஓம் கணேசாய நம:',
      name: 'க்ரீட பிரவேச விழா அழைப்பிதழ்',
      message: 'எங்கள் புதிய இல்லத்தின் க்ரீட பிரவேச விழாவில் உங்களை அழைக்க மகிழ்ச்சி அடைகிறோம். உங்கள் வருகை இந்த தருணத்தை மேலும் சிறப்பாக்கும்.',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.',
      invitedBy: 'திரு. மனீஷ் அகர்வால் & திருமதி. அனுஷ்கா அகர்வால்'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      date: 'തീയതി',
      venue: 'വേദി',
      invitedBy: 'ക്ഷണിച്ചത്'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'ഓം ഗണേശായ നമ:',
      name: 'ഗൃഹപ്രവേശ ചടങ്ങ് ക്ഷണം',
      message:
        'ഞങ്ങളുടെ പുതിയ വീട്ടിലെ ഗൃഹപ്രവേശന ചടങ്ങിലേക്ക് നിങ്ങളെ ക്ഷണിക്കാനാകുന്നത് ഞങ്ങൾക്ക് അതിയായ സന്തോഷമാണ്. നിങ്ങളുടെ സാന്നിധ്യം ഈ നിമിഷത്തെ അതീവ പ്രത്യേകമാക്കും.',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.',
      invitedBy: 'ശ്രീ മണീഷ് അഗർവാൽ & ശ്രീമതി അനുഷ്ക അഗർവാൽ'
    }
  }
};

export const houseWarmingChristianInvitationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      date: 'DATE',
      venue: 'VENUE',
      invitedBy: 'INVITED BY'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'HOLY CHRIST',
      name: 'House Warming Ceremony Invitation',
      message: 'We are overjoyed to invite you to the Housewarming Ceremony of our new home. Your presence will make this moment even more special.',
      date: 'Friday, Febraury 28th, 2024',
      venue: 'Swami Vivekanda Road, Indira Nagar, Bangalore.',
      invitedBy: 'Mr. Manish Agarwal & Mrs Anushka Agarwal'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      date: 'तारीख',
      venue: 'जगह',
      invitedBy: 'निमंत्रक'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'पवित्र मसीह',
      name: 'गृह प्रवेश समारोह निमंत्रण',
      message: 'हमें आपको अपने नए घर के गृहप्रवेश समारोह में आमंत्रित करते हुए बहुत खुशी हो रही है। आपकी मौजूदगी इस पल को और भी खास बना देगी.',
      date: 'शुक्रवार, 28 फ़रवरी 2024',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।',
      invitedBy: 'श्री. मनीष अग्रवाल & श्रीमती. अनुष्का अग्रवाल'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      date: 'పుట్టినరోజు తేదీ',
      venue: 'వేదిక',
      invitedBy: 'ఆహ్వానించువారు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'పవిత్ర క్రీస్తు',
      name: 'అగర్వాల్ వారి నూతన గృహప్రవేశ ఆహ్వానం',
      message:
        'మా కొత్త ఇంటి గృహప్రవేశం సందర్భంగా మిమ్మల్ని మా వేడుకకు ఆహ్వానించడానికి ఎంతో ఆనందంగా ఉంది.ఈ ప్రత్యేక సందర్భాన్ని మీరు మరింత ప్రత్యేకంగా చేయాలని ఆశిస్తున్నాము.',
      date: 'శుక్రవారం, ఫిబ్రవరి 28 2024',
      venue: 'వివేకానంద రోడ్డు, ఇందిరానగర్, బెంగుళూరు.',
      invitedBy: 'శ్రీ మనీష్ అగర్వాల్ & శ్రీమతి అనుష్క అగర్వాల్'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      date: 'ದಿನಾಂಕ',
      venue: 'ಸ್ಥಳ',
      invitedBy: 'ಆಹ್ವಾನಿತರು'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'ಪವಿತ್ರ ಕ್ರಿಸ್ತ',
      name: 'ಅಗರ್ವಾಲ್ ಅವರ ನೂತನ ಗೃಹಪ್ರವೇಶ ಆಹ್ವಾನ',
      message:
        'ನಮ್ಮ ಹೊಸ ಮನೆಯ ಗೃಹಪ್ರವೇಶ ಸಮಾರಂಭಕ್ಕೆ ನಿಮ್ಮನ್ನು ಆಹ್ವಾನಿಸಲು ನಾವು ತುಂಬಾ ಸಂತೋಷಪಡುತ್ತೇವೆ. ನಿಮ್ಮ ಉಪಸ್ಥಿತಿಯು ಈ ಕ್ಷಣವನ್ನು ಇನ್ನಷ್ಟು ವಿಶೇಷವಾಗಿಸುತ್ತದೆ.',
      date: 'ಶುಕ್ರವಾರ, ಫೆಬ್ರವರಿ 28, 2024',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.',
      invitedBy: 'ಶ್ರೀ ಮನೀಶ್ ಅಗರ್ವಾಲ್ & ಶ್ರೀಮತಿ ಅನುಷ್ಕಾ ಅಗರ್ವಾಲ್'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      date: 'தேதி',
      venue: 'இடம்',
      invitedBy: 'அழைப்பது'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'புனித கிறிஸ்து',
      name: 'க்ரீட பிரவேச விழா அழைப்பிதழ்',
      message: 'எங்கள் புதிய இல்லத்தின் க்ரீட பிரவேச விழாவில் உங்களை அழைக்க மகிழ்ச்சி அடைகிறோம். உங்கள் வருகை இந்த தருணத்தை மேலும் சிறப்பாக்கும்.',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.',
      invitedBy: 'திரு. மனீஷ் அகர்வால் & திருமதி. அனுஷ்கா அகர்வால்'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      date: 'തീയതി',
      venue: 'വേദി',
      invitedBy: 'ക്ഷണിച്ചത്'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      font: '',
      title: 'House Warming Ceremony Invitation',
      description: 'Create House Warming ceremony invitation and share with family and friends.',
      welcomeMsg: 'പരിശുദ്ധ ക്രിസ്തു',
      name: 'ഗൃഹപ്രവേശ ചടങ്ങ് ക്ഷണം',
      message:
        'ഞങ്ങളുടെ പുതിയ വീട്ടിലെ ഗൃഹപ്രവേശന ചടങ്ങിലേക്ക് നിങ്ങളെ ക്ഷണിക്കാനാകുന്നത് ഞങ്ങൾക്ക് അതിയായ സന്തോഷമാണ്. നിങ്ങളുടെ സാന്നിധ്യം ഈ നിമിഷത്തെ അതീവ പ്രത്യേകമാക്കും.',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.',
      invitedBy: 'ശ്രീ മണീഷ് അഗർവാൽ & ശ്രീമതി അനുഷ്ക അഗർവാൽ'
    }
  }
};
