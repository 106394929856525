import { useEffect, useState } from 'react';
import { formatQueryData } from '@/shared/hooks/hooksUtils.ts';
import { DateService } from '@/shared/services/DateService.ts';
import { useUserData } from '@/shared/services/UsersService';
import { useUserStore } from '@/shared/stores/UserStore';
import { StorageService } from '@/ui/services/StorageService';
import { setUserFromStorage } from '@/ui/services/WebUtils';
import { PencilRuler } from 'lucide-react';
import { NAlert, NDialog, NLoading, NTabs, NTabsContent } from 'nayan';
import SubHeader from '../helpers/SubHeader';
import { AccountProfileEdit } from './AccountProfileEdit';
import { AccountSaved } from './AccountSaved';
import { AccountVideos } from './AccountVideos';

const items = ['VIDEOS', 'SAVED'];

export const AccountProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userUuid = useUserStore(state => state.UUID);
  const [selected, setSelected] = useState(items[0]);
  const { isLoading, data }: any = useUserData(userUuid);
  const user = formatQueryData(data);

  useEffect(() => {
    if (user) {
      StorageService.set('UNAME', user.name);
      StorageService.set('UEMAIL', user.email);
      StorageService.set('UDOB', user.dob);
      StorageService.set('UABOUT', user.about);
      StorageService.set('UMOBILE', user.mobile);
      setUserFromStorage();
    }
  }, [user]);

  if (isLoading) {
    return <NLoading />;
  }

  if (!user) {
    return <div>No user found with {userUuid}.</div>;
  }

  return (
    <div className="profile">
      <NDialog isOpen={isOpen} onClose={() => setIsOpen(false)} title="Edit Profile">
        <AccountProfileEdit onClose={() => setIsOpen(false)} />
      </NDialog>
      <SubHeader title={user.name} clickButton={() => setIsOpen(true)} buttonText="EDIT" buttonIcon={PencilRuler} />
      <div className="mb-3 leading-relaxed">
        {user.email} | {user.mobile || 'No mobile added'} | {user.dob ? 'Born on ' + DateService.format(user.dob) : 'No date of birth added.'}
      </div>
      <div className="mb-3">{user.about || user.name + ' Invitations & Wishes.'}</div>
      {(!user.mobile || !user.dob) && (
        <NAlert
          title=""
          className="mb-3"
          type="INFO"
          message="Keep your profile updated to enjoy features like getting videos on WhatsApp, special birthday wishes, and easy account recovery."
        />
      )}
      <NTabs items={items} selected={selected} onChange={setSelected}>
        <NTabsContent item={items[0]}>
          <AccountVideos />
        </NTabsContent>
        <NTabsContent item={items[1]}>
          <AccountSaved />
        </NTabsContent>
      </NTabs>
    </div>
  );
};
