import { ReactNode, useEffect, useState } from 'react';
import { topTextFonts } from '@/ui/services/WebUtils';
import { GoogleFont } from '@remotion/google-fonts';
import { cn } from 'nayan';
import { AbsoluteFill, Audio, Img, OffthreadVideo, Sequence } from 'remotion';
import { Watermark } from './Watermark';

interface Props {
  children: ReactNode;
  className?: string;
  mediaClassName?: string;
  playbackRate?: number;
  musicVolume?: number;
  image?: any;
  music?: any;
  video?: any;
  color?: string;
  template?: any;
  data?: any;
  styles?: any;
}

export const Wraper = (props: Props) => {
  const { template, data, color = '#000000', styles = {}, className = '' } = props;
  const [loadedFont, setLoadedFont] = useState<string | null>(data.font);

  useEffect(() => {
    const loadDynamicFont = async () => {
      if (!!data.font) {
        try {
          const fonts = topTextFonts[data.language]?.find(font => font.family === data.font);
          const loaded = (await fonts?.load()) as GoogleFont;
          const { fontFamily } = loaded.loadFont();
          setLoadedFont(fontFamily);
        } catch (error) {
          console.error(`Error loading font: ${data.font}`, error);
        }
      }
    };

    loadDynamicFont();
  }, [data.font]);

  return (
    <AbsoluteFill className={`${className}`} style={loadedFont ? { ...styles, fontFamily: loadedFont } : styles}>
      {props.music && (
        <Sequence from={0}>
          <Audio src={props.music} volume={props.musicVolume || 1} />
        </Sequence>
      )}
      {props.image && (
        <AbsoluteFill>
          <Img className={cn(`w-full h-full blur opacity-75 ${props.mediaClassName}`)} src={props.image} />
        </AbsoluteFill>
      )}
      {props.video && (
        <AbsoluteFill>
          <OffthreadVideo src={props.video} className={cn(`w-full h-full ${props.mediaClassName}`)} playbackRate={props.playbackRate || 1} />
        </AbsoluteFill>
      )}
      {!data?.isPremium && <Watermark color={color} template={template} />}
      {props.children}
    </AbsoluteFill>
  );
};
