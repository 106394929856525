import { ReactNode, useEffect } from 'react';
import { handleVideoUpdateMutation } from '@/shared/services/VideosService';
import { useAppStore } from '@/shared/stores/AppStore';
import { SOCKET_ACTIONS } from '@/shared/types/types';
import { socketConnect } from '@/ui/services/WebUtils';
import { useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from 'nayan';
import Footer from './Footer';
import Header from './Header';

interface Props {
  children: ReactNode;
}

const Wrapper = (props: Props) => {
  const { children } = props;
  const queryClient = useQueryClient();
  const [uuid] = useLocalStorage('UUID', '');
  const appSocket = useAppStore((state: any) => state.appSocket);

  useEffect(() => {
    appSocket && appSocket.close();
    if (uuid) {
      const socketCon = socketConnect();
      useAppStore.setState({ appSocket: socketCon });
      console.log('SOCKET CONNECTED', uuid);
      socketCon.on(SOCKET_ACTIONS.UPDATE_VIDEO, handleVideoUpdate);
    }
  }, [uuid]);

  const handleVideoUpdate = (data: any) => {
    return data && handleVideoUpdateMutation(queryClient, data);
  };

  return (
    <>
      <Header />
      <div className="main mt-[70px] md:mt-[70px] min-h-[300px] md:min-h-[calc(100vh_-_340px)] xl:min-h-[calc(100vh_-_325px)]">{children}</div>
      <Footer />
    </>
  );
};

export default Wrapper;
