import { useState } from 'react';
import { useUpdateUserMutation } from '@/shared/services/UsersService';
import { useUserStore } from '@/shared/stores/UserStore';
import { NButton, NInput, NTextarea, useToast } from 'nayan';

interface Props {
  onClose: any;
}

export const AccountProfileEdit = (props: Props) => {
  const toast = useToast();
  const user = useUserStore(state => state);
  const [name, setName] = useState(user.UNAME || '');
  const [email, setEmail] = useState(user.UEMAIL || '');
  const [mobile, setMobile] = useState(user.UMOBILE || '');
  const [dob, setDob] = useState(user.UDOB || '');
  const [about, setAbout] = useState(user.UABOUT || '');
  const updateUserMutation = useUpdateUserMutation();

  const onSubmit = e => {
    e.preventDefault();
    if (!name || name?.length <= 3) {
      toast('Name should not empty and minimum of 3 characters.');
    } else if (mobile && mobile.length !== 10) {
      toast('Mobile number should be a valid 10 digit number.');
    } else {
      const finalUser = { uuid: user.UUID, name, mobile, about, dob };
      updateUserMutation
        .mutateAsync(finalUser)
        .then(() => {
          toast('User info updated successfully.');
          props.onClose();
        })
        .catch(e => toast(e.message));
    }
  };

  return (
    <div className="p-3">
      <form onSubmit={onSubmit}>
        <NInput label="Name" value={name} onChange={e => setName(e.target.value)} />
        <NInput label="Email" value={email} onChange={e => setEmail(e.target.value)} disabled={true} />
        <NInput label="Mobile" value={mobile} onChange={e => setMobile(e.target.value)} />
        <NInput type="date" label="Date of birth" value={dob} onChange={e => setDob(e.target.value)} />
        <NTextarea className="mb-3" label="About" value={about} onChange={e => setAbout(e.target.value)} />
        <NButton type="submit">Update</NButton>
      </form>
    </div>
  );
};
