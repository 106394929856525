import { useMemo } from 'react';
import welcomeImage from '@/remotion/assets/images/jesus.png';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import birthdayVideo from '@/remotion/assets/videos/cross-with-frame.mp4';
import { Wraper } from '@/remotion/helpers/Wraper';
import { getTemplateVideo } from '@/ui/services/TemplatesService';
import { templateSkus } from '@/ui/services/TemplateVideosService';
import { Series } from 'remotion';
import { z } from 'zod';
import { HouseWarmingDetails, HouseWarmingInviters, HouseWarmingPhoto, HouseWarmingTitle, HouseWarmingWelcome } from './HouseWarmingComponents';
import { houseWarmingInvitationSchema } from './HouseWarmingSchema';

const HouseWarmingInvitation: React.FC<z.infer<typeof houseWarmingInvitationSchema>> = props => {
  const { language, color = '#ffffff' } = props;
  const template = useMemo(() => getTemplateVideo('house-warming', templateSkus.houseWarmingChristmasCrossInvitation), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={birthdayVideo} playbackRate={0.9} music={birthdayMusic}>
      <Series>
        <Series.Sequence durationInFrames={50}>
          <HouseWarmingWelcome image={welcomeImage} props={props} labels={labels} color={color} className="w-[900px] ml-[50px]" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={50}>
          <HouseWarmingTitle props={props} labels={labels} color={color} className="w-[900px] ml-[50px]" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={80}>
          <HouseWarmingPhoto props={props} labels={labels} color={color} className="w-[900px] ml-[50px]" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <HouseWarmingDetails props={props} labels={labels} color={color} className="w-[900px] ml-[50px]" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={50}>
          <HouseWarmingInviters props={props} labels={labels} color={color} className="w-[900px] ml-[50px]" />
        </Series.Sequence>
      </Series>
    </Wraper>
  );
};

export default HouseWarmingInvitation;
