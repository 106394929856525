import { useMemo } from 'react';
import weddingImage from '@/remotion/assets/images/jesus.png';
import marriageAudio from '@/remotion/assets/sounds/wedding.mp3';
import marriageVideo from '@/remotion/assets/videos/christmas-tree.mp4';
import { Wraper } from '@/remotion/helpers/Wraper';
import { getTemplateVideo } from '@/ui/services/TemplatesService';
import { templateSkus } from '@/ui/services/TemplateVideosService';
import { Series } from 'remotion';
import { z } from 'zod';
import {
  MarriageBrideDetails,
  MarriageGroomDetails,
  MarriageMessage,
  MarriageTiming,
  MarriageTitle,
  MarriageVenue,
  MarriageWelcome
} from './MarriageComponents';
import { marriageSchema } from './MarriageSchema';

const MarriageInvitation: React.FC<z.infer<typeof marriageSchema>> = props => {
  const { color = '#ffffff' } = props;
  const template = useMemo(() => getTemplateVideo('marriage', templateSkus.engagementChristmasTreeInvitation), []);
  const labels = template?.languages[props.language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={marriageVideo} playbackRate={0.5} music={marriageAudio}>
      <Series>
        <Series.Sequence durationInFrames={60}>
          <MarriageWelcome props={props} labels={labels} color={color} image={weddingImage} className="w-[900px] ml-auto" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <MarriageTitle props={props} labels={labels} color={color} className="w-[900px] ml-auto" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={70}>
          <MarriageMessage props={props} labels={labels} color={color} className="w-[900px] ml-auto" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={70}>
          <MarriageGroomDetails props={props} labels={labels} color={color} className="w-[900px] ml-auto" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={70}>
          <MarriageBrideDetails props={props} labels={labels} color={color} className="w-[900px] ml-auto" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <MarriageTiming props={props} labels={labels} color={color} className="w-[900px] ml-auto" />
        </Series.Sequence>
        <Series.Sequence durationInFrames={60}>
          <MarriageVenue props={props} labels={labels} color={color} className="w-[900px] ml-auto" />
        </Series.Sequence>
      </Series>
    </Wraper>
  );
};

export default MarriageInvitation;
