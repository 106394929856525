import { useMemo } from 'react';
import SubHeader from '@/ui/components/helpers/SubHeader';
import { getTemplate } from '@/ui/services/TemplatesService';
import { useParams } from 'react-router';
import { TemplatesItem } from './TemplatesItem';

const TemplatesType = () => {
  const params: any = useParams();
  const template: any = useMemo(() => getTemplate(params.type), []);

  if (!template) {
    return <div>No Templates available</div>;
  }

  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title={`${template.name} Templates`} />
      <div className="leading-relaxed mb-5">{template.details}</div>
      {!template || (!template.videos.length && <div className="p-10 text-center">No templates available.</div>)}
      {!!template && (
        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-3 mb-3">
          {template.videos.map((video: any) => (
            <TemplatesItem key={video.sku} template={template} video={video} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TemplatesType;
