import { advertisementTags } from '@/remotion/compositions/advertisements/AdvertisementsSchema.ts';
import OfferAdvertisement from '@/remotion/compositions/advertisements/OfferAdvertisement';
import BirthdayInvitationWithAbstractDoubleRotation from '@/remotion/compositions/birthday/BirthdayInvitationWithAbstractDoubleRotation.tsx';
import BirthdayInvitationWithAbstractFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithAbstractFlowers.tsx';
import BirthdayInvitationWithAbstractLeaves from '@/remotion/compositions/birthday/BirthdayInvitationWithAbstractLeaves.tsx';
import BirthdayInvitationWithBibleReading from '@/remotion/compositions/birthday/BirthdayInvitationWithBibleReading.tsx';
import BirthdayInvitationWithBlueAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithBlueAbstract.tsx';
import BirthdayInvitationWithBlueLights from '@/remotion/compositions/birthday/BirthdayInvitationWithBlueLights.tsx';
import BirthdayInvitationWithChristmasCross from '@/remotion/compositions/birthday/BirthdayInvitationWithChristmasCross.tsx';
import BirthdayInvitationWithChristmasTree from '@/remotion/compositions/birthday/BirthdayInvitationWithChristmasTree.tsx';
import BirthdayInvitationWithColorfulBlasts from '@/remotion/compositions/birthday/BirthdayInvitationWithColorfulBlasts.tsx';
import BirthdayInvitationWithColorfulFireworks from '@/remotion/compositions/birthday/BirthdayInvitationWithColorfulFireworks.tsx';
import BirthdayInvitationWithColorfulLeaves from '@/remotion/compositions/birthday/BirthdayInvitationWithColorfulLeaves.tsx';
import BirthdayInvitationWithDarkGreenAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithDarkGreenAbstract.tsx';
import BirthdayInvitationWithEagleAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithEagleAbstract.tsx';
import BirthdayInvitationWithFire from '@/remotion/compositions/birthday/BirthdayInvitationWithFire.tsx';
import BirthdayInvitationWithFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowers.tsx';
import BirthdayInvitationWithFlowersAndButterflys from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowersAndButterflys.tsx';
import BirthdayInvitationWithFlowersAndLeaves from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowersAndLeaves.tsx';
import BirthdayInvitationWithFlowersWithGreenBackground from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowersWithGreenBackground.tsx';
import BirthdayInvitationWithFlowerVines from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowerVines.tsx';
import BirthdayInvitationWithGarlands from '@/remotion/compositions/birthday/BirthdayInvitationWithGarlands.tsx';
import BirthdayInvitationWithGlowingHearts from '@/remotion/compositions/birthday/BirthdayInvitationWithGlowingHearts.tsx';
import BirthdayInvitationWithGoldenLights from '@/remotion/compositions/birthday/BirthdayInvitationWithGoldenLights.tsx';
import BirthdayInvitationWithGreenAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithGreenAbstract.tsx';
import BirthdayInvitationWithHomeFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithHomeFlowers.tsx';
import BirthdayInvitationWithLeavesAndGreenBG from '@/remotion/compositions/birthday/BirthdayInvitationWithLeavesAndGreenBG.tsx';
import BirthdayInvitationWithLights from '@/remotion/compositions/birthday/BirthdayInvitationWithLights.tsx';
import BirthdayInvitationWithMarronAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithMarronAbstract.tsx';
import BirthdayInvitationWithMoonAndLights from '@/remotion/compositions/birthday/BirthdayInvitationWithMoonAndLights.tsx';
import BirthdayInvitationWithPurpleAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithPurpleAbstract.tsx';
import BirthdayInvitationWithRaining from '@/remotion/compositions/birthday/BirthdayInvitationWithRaining.tsx';
import BirthdayInvitationWithRotatingFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithRotatingFlowers.tsx';
import BirthdayInvitationWithRotatingRangoli from '@/remotion/compositions/birthday/BirthdayInvitationWithRotatingRangoli.tsx';
import BirthdayInvitationWithScrollingDiyas from '@/remotion/compositions/birthday/BirthdayInvitationWithScrollingDiyas.tsx';
import BirthdayInvitationWithSnowing from '@/remotion/compositions/birthday/BirthdayInvitationWithSnowing.tsx';
import BirthdayInvitationWithVintage from '@/remotion/compositions/birthday/BirthdayInvitationWithVintage.tsx';
import BirthdayInvitationWithVintageGreen from '@/remotion/compositions/birthday/BirthdayInvitationWithVintageGreen.tsx';
import BirthdayInvitationWithVintageOrange from '@/remotion/compositions/birthday/BirthdayInvitationWithVintageOrange.tsx';
import BirthdayInvitationWithYellowAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithYellowAbstract.tsx';
import BirthdayInvitationWithYellowStarAbstract from '@/remotion/compositions/birthday/BirthdayInvitationWithYellowStarAbstract.tsx';
import { birthdayTags } from '@/remotion/compositions/birthday/BirthdaySchema';
import BirthdayWishesWithBalloons from '@/remotion/compositions/birthday/BirthdayWishesWithBalloons.tsx';
import BirthdayWishesWithFlowers from '@/remotion/compositions/birthday/BirthdayWishesWithFlowers';
import BirthdayWishesWithSprings from '@/remotion/compositions/birthday/BirthdayWishesWithSprings.tsx';
import BirthdayWishesWithVectors from '@/remotion/compositions/birthday/BirthdayWishesWithVectors.tsx';
import { festivalTags } from '@/remotion/compositions/festivals/FestivalsSchema';
import FestivalWishesForChristmas from '@/remotion/compositions/festivals/FestivalWishesForChristmas';
import FestivalWishesForDiwali from '@/remotion/compositions/festivals/FestivalWishesForDiwali';
import FestivalWishesForDiwaliWithDiyas from '@/remotion/compositions/festivals/FestivalWishesForDiwaliWithDiyas';
import FestivalWishesForHoli from '@/remotion/compositions/festivals/FestivalWishesForHoli';
import FestivalWishesForIndependenceDay from '@/remotion/compositions/festivals/FestivalWishesForIndependenceDay';
import FestivalWishesForRakshaBandan from '@/remotion/compositions/festivals/FestivalWishesForRakshaBandan';
import FestivalWishesForRamadan from '@/remotion/compositions/festivals/FestivalWishesForRamadan';
import FestivalWishesForSnowman from '@/remotion/compositions/festivals/FestivalWishesForSnowman';
import HouseWarmingInvitationWithAbstractDoubleRotation from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithAbstractDoubleRotation.tsx';
import HouseWarmingInvitationWithAbstractFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithAbstractFlowers.tsx';
import HouseWarmingInvitationWithAbstractLeaves from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithAbstractLeaves.tsx';
import HouseWarmingInvitationWithBibleReading from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithBibleReading.tsx';
import HouseWarmingInvitationWithBlueAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithBlueAbstract.tsx';
import HouseWarmingInvitationWithBlueLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithBlueLights.tsx';
import HouseWarmingInvitationWithChristmasCross from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithChristmasCross.tsx';
import HouseWarmingInvitationWithChristmasTree from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithChristmasTree.tsx';
import HouseWarmingInvitationWithColorfulBlasts from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithColorfulBlasts.tsx';
import HouseWarmingInvitationWithColorfulFireworks from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithColorfulFireworks.tsx';
import HouseWarmingInvitationWithColorfulLeaves from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithColorfulLeaves.tsx';
import HouseWarmingInvitationWithDarkGreenAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithDarkGreenAbstract.tsx';
import HouseWarmingInvitationWithEagleAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithEagleAbstract.tsx';
import HouseWarmingInvitationWithFire from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFire.tsx';
import HouseWarmingInvitationWithFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowers.tsx';
import HouseWarmingInvitationWithFlowersAndButterflys from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowersAndButterflys.tsx';
import HouseWarmingInvitationWithFlowersAndLeaves from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowersAndLeaves.tsx';
import HouseWarmingInvitationWithFlowersWithGreenBackground from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowersWithGreenBackground.tsx';
import HouseWarmingInvitationWithFlowerVines from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowerVines.tsx';
import HouseWarmingInvitationWithGarlands from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGarlands.tsx';
import HouseWarmingInvitationWithGlowingHearts from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGlowingHearts.tsx';
import HouseWarmingInvitationWithGoldenLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGoldenLights.tsx';
import HouseWarmingInvitationWithGreenAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGreenAbstract.tsx';
import HouseWarmingInvitationWithHomeFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithHomeFlowers.tsx';
import HouseWarmingInvitationWithLeavesAndGreenBG from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithLeavesAndGreenBG.tsx';
import HouseWarmingInvitationWithLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithLights.tsx';
import HouseWarmingInvitationWithMarronAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithMarronAbstract.tsx';
import HouseWarmingInvitationWithMoonAndLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithMoonAndLights.tsx';
import HouseWarmingInvitationWithPurpleAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithPurpleAbstract.tsx';
import HouseWarmingInvitationWithRaining from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRaining.tsx';
import HouseWarmingInvitationWithRotatingFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRotatingFlowers.tsx';
import HouseWarmingInvitationWithRotatingRangoli from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRotatingRangoli.tsx';
import HouseWarmingInvitationWithScrollingDiyas from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithScrollingDiyas.tsx';
import HouseWarmingInvitationWithSnowing from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithSnowing.tsx';
import HouseWarmingInvitationWithVintage from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintage.tsx';
import HouseWarmingInvitationWithVintageGreen from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintageGreen.tsx';
import HouseWarmingInvitationWithVintageOrange from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintageOrange.tsx';
import HouseWarmingInvitationWithYellowAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithYellowAbstract.tsx';
import HouseWarmingInvitationWithYellowStarAbstract from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithYellowStarAbstract.tsx';
import { houseWarmingTags } from '@/remotion/compositions/house-warming/HouseWarmingSchema';
import AnniversaryWishesWithBalloons from '@/remotion/compositions/life-events/AnniversaryWishesWithBalloons.tsx';
import AnniversaryWishesWithFlowers from '@/remotion/compositions/life-events/AnniversaryWishesWithFlowers.tsx';
import AnniversaryWishesWithSprings from '@/remotion/compositions/life-events/AnniversaryWishesWithSprings.tsx';
import AnniversaryWishesWithVectors from '@/remotion/compositions/life-events/AnniversaryWishesWithVectors.tsx';
import { lifeEventsTags } from '@/remotion/compositions/life-events/LifeEventsSchema.ts';
import EngagementInvitationFlowersWithGreenBackground from '@/remotion/compositions/marriage/EngagementInvitationFlowersWithGreenBackground.tsx';
import EngagementInvitationWithAbstractDoubleRotation from '@/remotion/compositions/marriage/EngagementInvitationWithAbstractDoubleRotation.tsx';
import EngagementInvitationWithAbstractFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithAbstractFlowers.tsx';
import EngagementInvitationWithAbstractLeaves from '@/remotion/compositions/marriage/EngagementInvitationWithAbstractLeaves.tsx';
import EngagementInvitationWithBibleReading from '@/remotion/compositions/marriage/EngagementInvitationWithBibleReading.tsx';
import EngagementInvitationWithBlueAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithBlueAbstract.tsx';
import EngagementInvitationWithBlueLights from '@/remotion/compositions/marriage/EngagementInvitationWithBlueLights.tsx';
import EngagementInvitationWithChristmasCross from '@/remotion/compositions/marriage/EngagementInvitationWithChristmasCross.tsx';
import EngagementInvitationWithChristmasTree from '@/remotion/compositions/marriage/EngagementInvitationWithChristmasTree.tsx';
import EngagementInvitationWithColorfulBlasts from '@/remotion/compositions/marriage/EngagementInvitationWithColorfulBlasts.tsx';
import EngagementInvitationWithColorfulFireworks from '@/remotion/compositions/marriage/EngagementInvitationWithColorfulFireworks.tsx';
import EngagementInvitationWithColorfulLeaves from '@/remotion/compositions/marriage/EngagementInvitationWithColorfulLeaves.tsx';
import EngagementInvitationWithDarkGreenAbstarct from '@/remotion/compositions/marriage/EngagementInvitationWithDarkGreenAbstarct.tsx';
import EngagementInvitationWithEagleAbstarct from '@/remotion/compositions/marriage/EngagementInvitationWithEagleAbstarct.tsx';
import EngagementInvitationWithFire from '@/remotion/compositions/marriage/EngagementInvitationWithFire.tsx';
import EngagementInvitationWithFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithFlowers.tsx';
import EngagementInvitationWithFlowersAndButterflys from '@/remotion/compositions/marriage/EngagementInvitationWithFlowersAndButterflys.tsx';
import EngagementInvitationWithFlowersAndLeaves from '@/remotion/compositions/marriage/EngagementInvitationWithFlowersAndLeaves.tsx';
import EngagementInvitationWithFlowerVines from '@/remotion/compositions/marriage/EngagementInvitationWithFlowerVines.tsx';
import EngagementInvitationWithGlowingHearts from '@/remotion/compositions/marriage/EngagementInvitationWithGlowingHearts.tsx';
import EngagementInvitationWithGoldenLights from '@/remotion/compositions/marriage/EngagementInvitationWithGoldenLights.tsx';
import EngagementInvitationWithGreenAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithGreenAbstract.tsx';
import EngagementInvitationWithHomeFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithHomeFlowers.tsx';
import EngagementInvitationWithLeavesAndGreenBG from '@/remotion/compositions/marriage/EngagementInvitationWithLeavesAndGreenBG.tsx';
import EngagementInvitationWithLights from '@/remotion/compositions/marriage/EngagementInvitationWithLights.tsx';
import EngagementInvitationWithMarronAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithMarronAbstract.tsx';
import EngagementInvitationWithMoonAndLights from '@/remotion/compositions/marriage/EngagementInvitationWithMoonAndLights.tsx';
import EngagementInvitationWithPurpleAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithPurpleAbstract.tsx';
import EngagementInvitationWithRaining from '@/remotion/compositions/marriage/EngagementInvitationWithRaining.tsx';
import EngagementInvitationWithRotatingFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithRotatingFlowers.tsx';
import EngagementInvitationWithRotatingRangoli from '@/remotion/compositions/marriage/EngagementInvitationWithRotatingRangoli.tsx';
import EngagementInvitationWithScrollingDiyas from '@/remotion/compositions/marriage/EngagementInvitationWithScrollingDiyas.tsx';
import EngagementInvitationWithSnowing from '@/remotion/compositions/marriage/EngagementInvitationWithSnowing.tsx';
import EngagementInvitationWithVintage from '@/remotion/compositions/marriage/EngagementInvitationWithVintage.tsx';
import EngagementInvitationWithVintageGreen from '@/remotion/compositions/marriage/EngagementInvitationWithVintageGreen.tsx';
import EngagementInvitationWithVintageOrange from '@/remotion/compositions/marriage/EngagementInvitationWithVintageOrange.tsx';
import EngagementInvitationWithYellowAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithYellowAbstract.tsx';
import EngagementInvitationWithYellowStarAbstract from '@/remotion/compositions/marriage/EngagementInvitationWithYellowStarAbstract.tsx';
import MarriageInvitationFlowersWithGreenBackground from '@/remotion/compositions/marriage/MarriageInvitationFlowersWithGreenBackground.tsx';
import MarriageInvitationWithAbstractDoubleRotation from '@/remotion/compositions/marriage/MarriageInvitationWithAbstractDoubleRotation.tsx';
import MarriageInvitationWithAbstractFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithAbstractFlowers.tsx';
import MarriageInvitationWithAbstractLeaves from '@/remotion/compositions/marriage/MarriageInvitationWithAbstractLeaves.tsx';
import MarriageInvitationWithBibleReading from '@/remotion/compositions/marriage/MarriageInvitationWithBibleReading.tsx';
import MarriageInvitationWithBlueAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithBlueAbstract.tsx';
import MarriageInvitationWithBlueLights from '@/remotion/compositions/marriage/MarriageInvitationWithBlueLights.tsx';
import MarriageInvitationWithChristmasCross from '@/remotion/compositions/marriage/MarriageInvitationWithChristmasCross.tsx';
import MarriageInvitationWithChristmasTree from '@/remotion/compositions/marriage/MarriageInvitationWithChristmasTree.tsx';
import MarriageInvitationWithColorfulBlasts from '@/remotion/compositions/marriage/MarriageInvitationWithColorfulBlasts.tsx';
import MarriageInvitationWithColorfulFireworks from '@/remotion/compositions/marriage/MarriageInvitationWithColorfulFireworks.tsx';
import MarriageInvitationWithColorfulLeaves from '@/remotion/compositions/marriage/MarriageInvitationWithColorfulLeaves.tsx';
import MarriageInvitationWithDarkGreenAbstarct from '@/remotion/compositions/marriage/MarriageInvitationWithDarkGreenAbstarct.tsx';
import MarriageInvitationWithEagleAbstarct from '@/remotion/compositions/marriage/MarriageInvitationWithEagleAbstarct.tsx';
import MarriageInvitationWithFire from '@/remotion/compositions/marriage/MarriageInvitationWithFire.tsx';
import MarriageInvitationWithFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithFlowers';
import MarriageInvitationWithFlowersAndButterflys from '@/remotion/compositions/marriage/MarriageInvitationWithFlowersAndButterflys.tsx';
import MarriageInvitationWithFlowersAndLeaves from '@/remotion/compositions/marriage/MarriageInvitationWithFlowersAndLeaves.tsx';
import MarriageInvitationWithFallingFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithFlowerVines.tsx';
import MarriageInvitationWithGarlands from '@/remotion/compositions/marriage/MarriageInvitationWithGarlands';
import MarriageInvitationWithGlowingHearts from '@/remotion/compositions/marriage/MarriageInvitationWithGlowingHearts.tsx';
import MarriageInvitationWithGoldenLights from '@/remotion/compositions/marriage/MarriageInvitationWithGoldenLights.tsx';
import MarriageInvitationWithGreenAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithGreenAbstract.tsx';
import MarriageInvitationWithHomeFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithHomeFlowers.tsx';
import MarriageInvitationWithLeavesAndGreenBG from '@/remotion/compositions/marriage/MarriageInvitationWithLeavesAndGreenBG.tsx';
import MarriageInvitationWithLights from '@/remotion/compositions/marriage/MarriageInvitationWithLights';
import MarriageInvitationWithMarronAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithMarronAbstract.tsx';
import MarriageInvitationWithMoonAndLights from '@/remotion/compositions/marriage/MarriageInvitationWithMoonAndLights.tsx';
import MarriageInvitationWithPurpleAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithPurpleAbstract.tsx';
import MarriageInvitationWithRaining from '@/remotion/compositions/marriage/MarriageInvitationWithRaining.tsx';
import MarriageInvitationWithRotatingFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithRotatingFlowers';
import MarriageInvitationWithRotatingRangoli from '@/remotion/compositions/marriage/MarriageInvitationWithRotatingRangoli.tsx';
import MarriageInvitationWithScrollingDiyas from '@/remotion/compositions/marriage/MarriageInvitationWithScrollingDiyas.tsx';
import MarriageInvitationWithSnowing from '@/remotion/compositions/marriage/MarriageInvitationWithSnowing.tsx';
import MarriageInvitationWithVintage from '@/remotion/compositions/marriage/MarriageInvitationWithVintage';
import MarriageInvitationWithVintageGreen from '@/remotion/compositions/marriage/MarriageInvitationWithVintageGreen.tsx';
import MarriageInvitationWithVintageOrange from '@/remotion/compositions/marriage/MarriageInvitationWithVintageOrange.tsx';
import MarriageInvitationWithYellowAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithYellowAbstract.tsx';
import MarriageInvitationWithYellowStarAbstract from '@/remotion/compositions/marriage/MarriageInvitationWithYellowStarAbstract.tsx';
import { marriageTags } from '@/remotion/compositions/marriage/MarriageSchema';
import LogoFadeInAnimation from '@/remotion/compositions/technology/LogoFadeInAnimation';
import LogoHeartBeatAnimation from '@/remotion/compositions/technology/LogoHeartBeatAnimation';
import LogoRotationAnimation from '@/remotion/compositions/technology/LogoRotationAnimation';
import LogoSpringAnimation from '@/remotion/compositions/technology/LogoSpringAnimation';
import SocialMediaCountAnimation from '@/remotion/compositions/technology/SocialMediaCountAnimation.tsx';
import { technologyTags } from '@/remotion/compositions/technology/TechnologySchema';
import {
  advertisementVideos,
  birthdayVideos,
  festivalVideos,
  houseWarmingVideos,
  kidsVideos,
  lifeEventsVideos,
  marriageVideos,
  technologyVideos,
  templateSkus
} from '@/ui/services/TemplateVideosService';
import { VIDEO_TYPES } from '../shared/config/constants';

export const getTemplate = (type: string) => {
  return templates.find(t => t.sku === type);
};

export const getTemplateByTag = (tag: string) => {
  return templates.find(t => t.tags.find(tg => tg.sku === tag));
};

export const getTemplateVideo = (type, videoSku: any) => {
  const template = templates.find(t => t.sku === type);
  const video = template?.videos?.find((v: any) => v.sku === videoSku);
  if (!template || !video) return null;
  return { ...video, template: { name: template.name, sku: template.sku, tags: template.tags } };
};

export const compositionMapping = {
  // Birthday wishes
  [`${templateSkus.birthdayBalloonsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithBalloons,
  [`${templateSkus.birthdayFlowersWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithFlowers,
  [`${templateSkus.birthdayVectorsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithVectors,
  [`${templateSkus.birthdaySpringsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithSprings,
  // Birthday invitations
  [`${templateSkus.birthdayAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithAbstractDoubleRotation,
  [`${templateSkus.birthdayAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithAbstractFlowers,
  [`${templateSkus.birthdayAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithAbstractLeaves,
  [`${templateSkus.birthdayBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithBibleReading,
  [`${templateSkus.birthdayBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithBlueAbstract,
  [`${templateSkus.birthdayBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithBlueLights,
  [`${templateSkus.birthdayChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithChristmasCross,
  [`${templateSkus.birthdayChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithChristmasTree,
  [`${templateSkus.birthdayColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithColorfulBlasts,
  [`${templateSkus.birthdayColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithColorfulFireworks,
  [`${templateSkus.birthdayColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithColorfulLeaves,
  [`${templateSkus.birthdayDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithDarkGreenAbstract,
  [`${templateSkus.birthdayeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithEagleAbstract,
  [`${templateSkus.birthdayFireInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFire,
  [`${templateSkus.birthdayFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowersAndButterflys,
  [`${templateSkus.birthdayFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowersAndLeaves,
  [`${templateSkus.birthdayFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowers,
  [`${templateSkus.birthdayFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowerVines,
  [`${templateSkus.birthdayFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowersWithGreenBackground,
  [`${templateSkus.birthdayGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGarlands,
  [`${templateSkus.birthdayGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGlowingHearts,
  [`${templateSkus.birthdayGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGoldenLights,
  [`${templateSkus.birthdayGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGreenAbstract,
  [`${templateSkus.birthdayHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithHomeFlowers,
  [`${templateSkus.birthdayLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithLeavesAndGreenBG,
  [`${templateSkus.birthdayLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithLights,
  [`${templateSkus.birthdayMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithMarronAbstract,
  [`${templateSkus.birthdayMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithMoonAndLights,
  [`${templateSkus.birthdayPurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithPurpleAbstract,
  [`${templateSkus.birthdayRainingInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRaining,
  [`${templateSkus.birthdayRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRotatingRangoli,
  [`${templateSkus.birthdayRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRotatingFlowers,
  [`${templateSkus.birthdayScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithScrollingDiyas,
  [`${templateSkus.birthdaySnowingInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithSnowing,
  [`${templateSkus.birthdayVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintageGreen,
  [`${templateSkus.birthdayVintageInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintage,
  [`${templateSkus.birthdayVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintageOrange,
  [`${templateSkus.birthdayYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithYellowAbstract,
  [`${templateSkus.birthdayYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithYellowStarAbstract,
  // Marriage
  [`${templateSkus.marriageFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowers,
  [`${templateSkus.marriageVintageInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintage,
  [`${templateSkus.marriageLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithLights,
  [`${templateSkus.marriageRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRotatingFlowers,
  [`${templateSkus.marriageGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGarlands,
  [`${templateSkus.marriageFireInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFire,
  [`${templateSkus.marriageAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithAbstractFlowers,
  [`${templateSkus.marriageAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithAbstractLeaves,
  [`${templateSkus.marriageAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithAbstractDoubleRotation,
  [`${templateSkus.marriageBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithBibleReading,
  [`${templateSkus.marriageBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithBlueAbstract,
  [`${templateSkus.marriageBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithBlueLights,
  [`${templateSkus.marriageSnowingInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithSnowing,
  [`${templateSkus.marriageChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithChristmasTree,
  [`${templateSkus.marriageColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithColorfulBlasts,
  [`${templateSkus.marriageColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithColorfulFireworks,
  [`${templateSkus.marriageColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithColorfulLeaves,
  [`${templateSkus.marriageChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithChristmasCross,
  [`${templateSkus.marriageDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithDarkGreenAbstarct,
  [`${templateSkus.marriageeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithEagleAbstarct,
  [`${templateSkus.marriageFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFallingFlowers,
  [`${templateSkus.marriageFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowersAndButterflys,
  [`${templateSkus.marriageFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowersAndLeaves,
  [`${templateSkus.marriageFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationFlowersWithGreenBackground,
  [`${templateSkus.marriageGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGlowingHearts,
  [`${templateSkus.marriageGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGoldenLights,
  [`${templateSkus.marriageGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGreenAbstract,
  [`${templateSkus.marriageHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithHomeFlowers,
  [`${templateSkus.marriageLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithLeavesAndGreenBG,
  [`${templateSkus.marriageMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithMarronAbstract,
  [`${templateSkus.marriageMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithMoonAndLights,
  [`${templateSkus.marriagePurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithPurpleAbstract,
  [`${templateSkus.marriageRainingInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRaining,
  [`${templateSkus.marriageRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRotatingRangoli,
  [`${templateSkus.marriageScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithScrollingDiyas,
  [`${templateSkus.marriageVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintageGreen,
  [`${templateSkus.marriageVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintageOrange,
  [`${templateSkus.marriageYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithYellowAbstract,
  [`${templateSkus.marriageYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithYellowStarAbstract,
  // Engagement
  [`${templateSkus.engagementAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithAbstractDoubleRotation,
  [`${templateSkus.engagementAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithAbstractFlowers,
  [`${templateSkus.engagementAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithAbstractLeaves,
  [`${templateSkus.engagementBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithBibleReading,
  [`${templateSkus.engagementBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithBlueAbstract,
  [`${templateSkus.engagementBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithBlueLights,
  [`${templateSkus.engagementChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithChristmasCross,
  [`${templateSkus.engagementChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithChristmasTree,
  [`${templateSkus.engagementColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithColorfulBlasts,
  [`${templateSkus.engagementColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithColorfulFireworks,
  [`${templateSkus.engagementColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithColorfulLeaves,
  [`${templateSkus.engagementDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithDarkGreenAbstarct,
  [`${templateSkus.engagementeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithEagleAbstarct,
  [`${templateSkus.engagementFireInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFire,
  [`${templateSkus.engagementFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowerVines,
  [`${templateSkus.engagementFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowersAndButterflys,
  [`${templateSkus.engagementFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowersAndLeaves,
  [`${templateSkus.engagementFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowers,
  [`${templateSkus.engagementFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationFlowersWithGreenBackground,
  [`${templateSkus.engagementGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGlowingHearts,
  [`${templateSkus.engagementGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGoldenLights,
  [`${templateSkus.engagementGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGreenAbstract,
  [`${templateSkus.engagementHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithHomeFlowers,
  [`${templateSkus.engagementLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithLeavesAndGreenBG,
  [`${templateSkus.engagementLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithLights,
  [`${templateSkus.engagementMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithMarronAbstract,
  [`${templateSkus.engagementMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithMoonAndLights,
  [`${templateSkus.engagementPurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithPurpleAbstract,
  [`${templateSkus.engagementRainingInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRaining,
  [`${templateSkus.engagementRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRotatingRangoli,
  [`${templateSkus.engagementRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRotatingFlowers,
  [`${templateSkus.engagementScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithScrollingDiyas,
  [`${templateSkus.engagementSnowingInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithSnowing,
  [`${templateSkus.engagementVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintageGreen,
  [`${templateSkus.engagementVintageInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintage,
  [`${templateSkus.engagementVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintageOrange,
  [`${templateSkus.engagementYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithYellowAbstract,
  [`${templateSkus.engagementYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithYellowStarAbstract,
  // House Warming
  [`${templateSkus.houseWarmingAbstractDoubleRotationInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithAbstractDoubleRotation,
  [`${templateSkus.houseWarmingAbstractFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithAbstractFlowers,
  [`${templateSkus.houseWarmingAbstractLeavesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithAbstractLeaves,
  [`${templateSkus.houseWarmingBibleReadingInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithBibleReading,
  [`${templateSkus.houseWarmingBlueAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithBlueAbstract,
  [`${templateSkus.houseWarmingBlueLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithBlueLights,
  [`${templateSkus.houseWarmingChristmasCrossInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithChristmasCross,
  [`${templateSkus.houseWarmingChristmasTreeInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithChristmasTree,
  [`${templateSkus.houseWarmingColorfulBlastsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithColorfulBlasts,
  [`${templateSkus.houseWarmingColorfulFireworksInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithColorfulFireworks,
  [`${templateSkus.houseWarmingColorfulLeavesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithColorfulLeaves,
  [`${templateSkus.houseWarmingDarkgreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithDarkGreenAbstract,
  [`${templateSkus.houseWarmingeagleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithEagleAbstract,
  [`${templateSkus.houseWarmingFireInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFire,
  [`${templateSkus.houseWarmingFlowersAndButterflysInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowersAndButterflys,
  [`${templateSkus.houseWarmingFlowersAndLeavesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowersAndLeaves,
  [`${templateSkus.houseWarmingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowers,
  [`${templateSkus.houseWarmingFlowerVinesInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowerVines,
  [`${templateSkus.houseWarmingFlowersWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowersWithGreenBackground,
  [`${templateSkus.houseWarmingGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGarlands,
  [`${templateSkus.houseWarmingGlowingHeartsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGlowingHearts,
  [`${templateSkus.houseWarmingGoldLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGoldenLights,
  [`${templateSkus.houseWarmingGreenAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGreenAbstract,
  [`${templateSkus.houseWarmingHomeFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithHomeFlowers,
  [`${templateSkus.houseWarmingLeavesWithGreenBGInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithLeavesAndGreenBG,
  [`${templateSkus.houseWarmingLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithLights,
  [`${templateSkus.houseWarmingMarronAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithMarronAbstract,
  [`${templateSkus.houseWarmingMoonAndLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithMoonAndLights,
  [`${templateSkus.houseWarmingPurpleAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithPurpleAbstract,
  [`${templateSkus.houseWarmingRainingInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRaining,
  [`${templateSkus.houseWarmingRedRotatingRangoliInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRotatingRangoli,
  [`${templateSkus.houseWarmingRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRotatingFlowers,
  [`${templateSkus.houseWarmingScrollingDiyasInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithScrollingDiyas,
  [`${templateSkus.houseWarmingSnowingInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithSnowing,
  [`${templateSkus.houseWarmingVintageGreenInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintageGreen,
  [`${templateSkus.houseWarmingVintageInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintage,
  [`${templateSkus.houseWarmingVintageOrangeInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintageOrange,
  [`${templateSkus.houseWarmingYellowAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithYellowAbstract,
  [`${templateSkus.houseWarmingYellowStarAbstractInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithYellowStarAbstract,
  // Festivals
  [`${templateSkus.festivalRakshaBandanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRakshaBandan,
  [`${templateSkus.festivalRamadanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRamadan,
  [`${templateSkus.festivalSnowmanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForSnowman,
  [`${templateSkus.festivalHoliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForHoli,
  [`${templateSkus.festivalIndependenceDayWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForIndependenceDay,
  [`${templateSkus.festivalDiwaliWithDiyasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwaliWithDiyas,
  [`${templateSkus.festivalChristmasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmas,
  [`${templateSkus.festivalDiwaliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwali,
  // Life Events
  [`${templateSkus.anniversaryBalloonsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithBalloons,
  [`${templateSkus.anniversaryFlowersWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithFlowers,
  [`${templateSkus.anniversaryVectorsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithVectors,
  [`${templateSkus.anniversarySpringsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithSprings,
  // Technology
  [`${templateSkus.logoRotationAnimation}-${VIDEO_TYPES.WEB}`]: LogoRotationAnimation,
  [`${templateSkus.logoRotationAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoRotationAnimation,
  [`${templateSkus.logoHeartBeatAnimation}-${VIDEO_TYPES.WEB}`]: LogoHeartBeatAnimation,
  [`${templateSkus.logoHeartBeatAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoHeartBeatAnimation,
  [`${templateSkus.logoFadeInAnimation}-${VIDEO_TYPES.WEB}`]: LogoFadeInAnimation,
  [`${templateSkus.logoFadeInAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoFadeInAnimation,
  [`${templateSkus.logoSpringAnimation}-${VIDEO_TYPES.WEB}`]: LogoSpringAnimation,
  [`${templateSkus.logoSpringAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoSpringAnimation,
  [`${templateSkus.socialMediaCountAnimation}-${VIDEO_TYPES.WEB}`]: SocialMediaCountAnimation,
  // Advertisements
  [`${templateSkus.offerAdvertisement}-${VIDEO_TYPES.WEB}`]: OfferAdvertisement
};

export const templates = [
  {
    name: 'Birthday',
    sku: 'birthday',
    details:
      "Birthday wishes and invitations video templates offer a fun and creative way to celebrate special occasions. These templates come pre-designed with vibrant animations, music, and custom text options, allowing users to personalize heartfelt messages and event details. Perfect for sending digital birthday greetings or inviting guests to parties, they save time while delivering visually stunning and memorable content. Whether for kids' birthdays, milestone celebrations, or surprise parties, video templates add a unique touch to the festivities with ease and style.",
    tags: birthdayTags,
    videos: birthdayVideos
  },
  {
    name: 'Marriage',
    sku: 'marriage',
    details:
      'Marriage and engagement invitation video templates provide a modern and elegant way to announce your special day. These templates are pre-designed with beautiful animations, romantic themes, and customizable options for adding personal details like names, dates, and venue information. Ideal for sharing online, these videos offer a captivating and memorable alternative to traditional invitations, perfect for setting the tone of your wedding or engagement event. Whether formal or casual, these templates help you create stunning, personalized invitations effortlessly.',
    tags: marriageTags,
    videos: marriageVideos
  },
  {
    name: 'House Warming',
    sku: 'house-warming',
    details:
      'Housewarming invitation video templates offer a stylish and convenient way to invite friends and family to celebrate your new home. With customizable designs featuring warm themes, cozy animations, and editable text, these templates make it easy to personalize details like the date, time, and address. Perfect for sharing digitally, these videos add a creative and welcoming touch to your housewarming celebration, helping you set the right mood for this exciting milestone.',
    tags: houseWarmingTags,
    videos: houseWarmingVideos
  },
  {
    name: 'Festivals',
    sku: 'festivals',
    details:
      'Festival wishes video templates are a vibrant and festive way to send greetings during special occasions like Diwali, Christmas, Eid, and more. These templates feature lively animations, colorful themes, and customizable options for personal messages, making it easy to create and share joyful greetings. Perfect for spreading holiday cheer, they add a unique, visually stunning touch to your wishes, ensuring your greetings stand out and bring smiles to loved ones.',
    tags: festivalTags,
    videos: festivalVideos
  },
  {
    name: 'Kids',
    sku: 'kids',
    details:
      "Kids' function video templates, such as for birthdays, dhoti functions, and half saree ceremonies, provide a playful and vibrant way to celebrate special milestones. These templates feature charming animations, colorful designs, and customizable text, allowing you to personalize event details and messages. Perfect for creating digital invitations or video highlights, they capture the joy and tradition of these memorable moments with ease and creativity. Ideal for sharing with friends and family, these templates add a festive and heartwarming touch to your child’s special day.",
    tags: [...birthdayTags],
    videos: kidsVideos
  },
  {
    name: 'Life Events',
    sku: 'life-events',
    details:
      "Life event video templates, such as for anniversaries, retirements, and memorials, offer a heartfelt way to celebrate and honor significant milestones. These templates come with elegant designs, soothing animations, and customizable options to add personal details like messages, names, and dates. Perfect for creating memorable digital tributes, invitations, or celebration videos, they help capture the emotions of these special moments, whether commemorating years of togetherness, a career's conclusion, or paying tribute to a loved one’s memory.",
    tags: lifeEventsTags,
    videos: lifeEventsVideos
  },
  {
    name: 'Technology',
    sku: 'technology',
    details:
      'Animating logos, YouTube follower achievements, and Twitter followers video templates provide an engaging way to highlight milestones and boost brand visibility. These templates feature dynamic animations and sleek designs, perfect for showcasing growing follower counts or celebrating social media achievements. With customizable options, you can personalize your brand’s logo or follower milestones, making them ideal for social media shoutouts, promotional videos, or celebratory posts. These templates add a professional and captivating touch to your content, enhancing engagement and recognition across platforms.',
    tags: technologyTags,
    videos: technologyVideos
  },
  {
    name: 'Advertisements',
    sku: 'advertisements',
    details:
      'Animating advertisements for social media and product promotion video templates offer a dynamic and eye-catching way to market your brand. These templates feature bold animations, engaging visuals, and customizable text, making it easy to showcase your products, services, or special offers. Ideal for platforms like Instagram, Facebook, and YouTube, they help capture attention quickly and convey your message effectively. With professional designs and seamless transitions, these templates are perfect for creating promotional ads that boost visibility and drive engagement with your target audience.',
    tags: advertisementTags,
    videos: advertisementVideos
  }
];
